"use client";

import { cn } from "@/lib/utils/utils";
import {
  CircleDashed,
  GalleryVertical,
  Home,
  LucideIcon,
  MessagesSquare,
  User,
  Wand2,
} from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface MenuItem {
  href: string;
  icon: LucideIcon;
  text: string;
  isActive?: boolean;
}

const menuItems: MenuItem[] = [
  {
    href: "/",
    icon: Home,
    text: "Home",
  },
  {
    href: "/date",
    icon: GalleryVertical,
    text: "Date",
  },
  {
    href: "/create",
    icon: Wand2,
    text: "Create GF",
  },
  {
    href: "/chat",
    icon: MessagesSquare,
    text: "Chats",
  },
  {
    href: "/account",
    icon: User,
    text: "Account",
  },
];

export default function BottomNavbar() {
  const pathname = usePathname();

  const isChatInterface = pathname.includes("/chat/");
  if (isChatInterface) {
    return null;
  }

  return (
    <nav className="fixed z-10 bottom-0 inset-x-0 bg-secondary text-secondary-content px-2 py-2 overflow-hidden border border-black/25 md:max-w-md md:bottom-4 md:rounded-lg md:shadow-lg md:mx-auto">
      <ul className="flex items-center justify-center gap-2 max-w-md mx-auto">
        {menuItems.map((item, index) => {
          const isActive = item.href === pathname;

          const Icon: LucideIcon = item.icon || CircleDashed;
          return (
            <li key={index} className="relative grow rounded-md">
              <Link
                href={item.href}
                className="flex flex-col items-center justify-center gap-1"
              >
                <Icon
                  size={20}
                  strokeWidth={1.62}
                  className={cn(
                    "text-opacity-60",
                    isActive && "stroke-primary",
                  )}
                />
                <span
                  className={cn(
                    "text-xs font-normal",
                    isActive
                      ? "text-primary"
                      : "text-secondary-content text-opacity-60",
                  )}
                >
                  {item.text}
                </span>
              </Link>
              {isActive && (
                <div className="absolute -bottom-4 inset-x-0 mx-auto max-w-[70%] h-3 bg-primary rounded-md" />
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
